<template>
	<v-container>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>New Billing Request</v-toolbar-title>
					<div class="flex-grow-1"></div>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
                        <h4>Billing Information</h4> 
						<v-layout wrap>
							<v-flex xs12 sm12 md4>
								<v-text-field
									v-model="billingRequestNumber"
									label="Request No."
									readonly
								></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-menu
										v-model="req_date"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Request Date"
												v-model="request_date"
												required
												return-masked-value
												v-on="on">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="request_date"
											@change="req_date === false"
										></v-date-picker>
									</v-menu>
								</v-flex>
							<v-flex xs12 sm12 md4>
								<v-autocomplete
									class="remove-underline"
									v-model="payee_id"
									:items="filteredItems"
									editable
									hide-details
									item-text="payee_desc"
									item-value="id"
									label="Customer Name"
									@change="setDetails()"
								></v-autocomplete>
							</v-flex>
                            <v-flex xs12 sm12 md4>
								<v-text-field
									v-model="business_style"
									editable
									label="Business Style"
									readonly
								>
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field v-model="address" readonly label="Address" ></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field v-model="TIN" readonly label="TIN" ></v-text-field>
							</v-flex>
							<!-- <v-flex xs12 sm12 md4>
								<v-text-field v-model="payment_term" type="number" label="Payment Term (days)"></v-text-field>
							</v-flex> -->
							<v-flex md12>
								<v-text-field v-model="remarks" label="Remarks" :rules="default_rule"> </v-text-field>
							</v-flex>
						</v-layout>
					</v-container>
                    <div class="container">
                        <div class="large-12 medium-12 small-12 cell">
							<h4>Supporting Documents</h4><br>
                            <label>
                                <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
                            </label>
                        </div>
                    </div>
                    <v-container grid-list-md>
                        <h4>Service Information</h4>
						<v-flex md12>
								<v-simple-table dense v-if="details.length !== 0">
									<template v-slot:default>
										<thead>
										<tr>
											<th>#</th>
											<th>Service</th>
											<th>Amount</th>
											<!-- <th>Action</th> -->
										</tr>
										</thead>
										<tbody>
										<tr
											v-for="(detail, detail_key) in details"
											:key="detail.detail_key"
										>
											<td>{{ detail_key + 1 }}</td>
											<td>
												<v-autocomplete
													v-model="detail.service_id"
													:items="service_data"
													editable
													item-text="billing_service_name"
													:rules="default_rule"
													item-value="id"
												></v-autocomplete>
											</td>
											<td>
												<v-text-field 
													v-model="detail.amount"
													type="text"
													:rules="default_rule"
													class="input_number text-right">
												</v-text-field>
											</td>
											<!-- <td>
											<v-btn icon>
												<v-icon small color="red" @click="deleteService(detail_key, detail)">delete</v-icon>
											</v-btn>
											</td> -->
										</tr>
                                        <tr>
                                        <td colspan="2" style="text-align: right">
                                        <b>Total</b>
                                        </td>
                                        <td class="text-right">
											
                                        <b>{{total_amount}}</b>
                                        </td>
                                        </tr>
										</tbody>
									</template>
								</v-simple-table>
                                <!-- <v-btn right dark color="primary" @click="addService">
                                <v-icon>mdi-plus</v-icon>
                                <span>Add Service</span>
                                </v-btn> -->
							</v-flex>
					</v-container>
                    <v-container grid-list-md>
                        <h4>Approving Officers</h4>
						<v-layout>
							<v-flex md6>
								<v-autocomplete
									v-model="dept_approver1"
									:items="getApprover(1)"
									editable
									item-text="approver_name"
									item-value="user_id"
									label="Immediate Supervisor"
									:rules="default_rule"
								></v-autocomplete>
							</v-flex>
							<v-flex md6>
									<v-autocomplete
										v-model="dept_approver2"
										:items="getApprover(2)"
										editable
										item-text="approver_name"
										item-value="user_id"
										label="Department Head"
										:rules="default_rule"
									></v-autocomplete>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
				<div class="text-center">
					<v-dialog
						v-model="createDialog"
						persistent max-width="600"
					>
						<v-card>
							<v-card-title
								class="headline primary white--text"
								primary-title
							>
								Create Billing Request
							</v-card-title>
							<br>
							<v-card-text>
								Are you sure you want to submit the form?
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									text
									@click="save()"
								>
									Save
								</v-btn>
								<v-btn
									color="red"
									text
									@click="createDialog = false"
								>
									Cancel
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
				<div class="text-center">
					<v-dialog
						v-model="draftBilling"
						persistent max-width="600"
					>
						<v-card>
							<v-card-title
								class="headline primary white--text"
								primary-title
							>
								Save as draft Billing Request
							</v-card-title>
							<br>
							<v-card-text>
								Are you sure you want to submit the form?
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									text
									@click="save('draft')"
								>
									Save
								</v-btn>
								<v-btn
									color="red"
									text
									@click="draftBilling = false"
								>
									Cancel
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
						<v-btn text color="blue accent-4" @click="draftBilling = true">Save as Draft</v-btn>
						<v-btn text color="blue accent-4" @click="createDialog = true">Create Billing Request</v-btn>
						<v-btn dark color="red accent-4" @click="removeStorage()">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, addDays } from 'date-fns';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';

export default {
	data: () => ({
		upload_files: '',
		isvalid: true,
		module_types: [
			{code: 'F', name: 'File Maintenance'},
			{code: 'T', name: 'Transaction'},
			{code: 'R', name: 'Report'},
		],
		details: [
			{service_id: '', amount: ''}
		],
		req_date: false,
		draftBilling : false,
		createDialog: false,
		payee_id: '',
		request_date: '',
		payment_term: 0,
		due_date: '',
		business_style: '',
		address: '',
		TIN: '',
		remarks: '',
		tax_percentage: '',
		service_data: [],
		dept_approver1: '',
		dept_approver2: '',
		total_amount: 0,
		atc_code: '',
		default_rule: [
			v => !!v || 'Required'
		],
		rate: 0
	}),

	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			editedItem: 'trxbilling/editedItem',
			formTitle: 'trxbilling/formTitle',
			valid: 'trxbilling/valid',
			dialog: 'trxbilling/dialog',
			editedIndex: 'trxbilling/editedIndex',
			payeefile: 'payefile/payefiles',
			services: 'services/services',
			businessType: 'payefile/businessType',
			billingRequestNumber: 'trxbilling/billingRequestNumber',
			billingapprovingofficers: 'billingapprovingofficers/approvingofficers',
			alphataxcodes: 'alphataxcode/alphataxcodes',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		},
		filteredItems(){
			let payee = this.payees;
			let services = this.services;
			let filterPayee = [];
			
			services.forEach(detail => {
				let items = payee.filter((i)=>i.id === detail.payee_id);
				if(items.length > 0){
					items.forEach(pay => filterPayee.push(pay));
				}

			});
			return filterPayee;
		}
	},
	watch: {
		payment_term: {
			handler(val){
				this.computeDueDate(val);
			}
		},
		billing_date: {
			handler(){
				this.computeDueDate(this.payment_term);
			}
		},
		details: {
			handler(val){
				val.map(det => {
					det.amount = numeral(det.amount).format('0,0.[00]');
				});
				let total_amount = val.reduce((sum, details) => {
					if (details.amount === '') {
						details.amount = 0;
					}

					return sum + parseFloat(details.amount.replace(/,/g, ''));
				}, 0);
				this.total_amount = numeral(total_amount).format('0,0.[00]');

				// this.getwtax(val.service_id);
			},
			deep: true
		}
	},
	async mounted() {
		this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('services/getServices');
		this.$store.dispatch('billingapprovingofficers/getApprovingOfficers');
		this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');

		this.request_date = format(new Date(), 'yyyy-MM-dd');
		// this.due_date = format(new Date(), 'yyyy-MM-dd');
		this.service_data = [];
	},

	methods: {
		// getDecimal(key) {
		// 	console.log(this.details[key]);
		// 	this.details[key].amount = numeral(parseFloat(this.details[key].amount)).format('0,0.[00]');
		// },
		computeDueDate(days){
			this.due_date = format(addDays(new Date(this.billing_date), days), 'yyyy-MM-dd');
		},
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('trxbilling/setValid', false);
				this.$store.dispatch('trxbilling/setDialog', false);
				this.$store.dispatch('trxbilling/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},
		removeStorage(){
			this.$router.push('/trx-billing');
		},
		// getwtax(id){
			
		// 	let filterAlphaTaxCode = this.alphataxcodes.data.filter(taxCode => {
		// 		return taxCode.atc_code === this.atc_code;
		// 	});

		// 	this.rate = filterAlphaTaxCode[0].atc_rate;

		// 	let service = this.services.filter(serv => serv.id === id);
		// 	let tax = service.filter(det => det.account_flag === 4);

		// 	if(tax.length > 0){
		// 		this.rate = filterAlphaTaxCode[0].atc_rate;
		// 	}
		// },

		async save(type) {
			this.$store.dispatch('trxbilling/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			let headers = [];
			let details = [];
			let total_amount = 0;
			this.details.forEach(det => {
				let amount = parseFloat(det.amount.replace(/,/g, ''));
				let service = this.services.filter(serv => serv.id === det.service_id);
				if(service.length > 0){
					service[0]['billingentries'].forEach(ent => {
						let debit_amount = 0;
						let credit_amount = 0;
						let remarks = '';
						let vat = 0;
						if(ent.account_flag === 2){
							remarks = 'Income Amount';
							if(service[0].vat_category === 1){
								vat = (amount / 1.12) * (12 / 100);
								credit_amount = amount - vat;
							} else { credit_amount = amount; }
						} else if (ent.account_flag === 3 && service[0].vat_category !== 3){
							remarks = 'Vat Amount';
							if(service[0].vat_category === 1){
								credit_amount = (amount / 1.12) * (12 / 100);
							} else if (service[0].vat_category === 2) {
								vat = amount * (12 / 100);
								credit_amount = vat;
							}
						} else if (ent.account_flag === 1){
							remarks = 'Total Amount';
							if(service[0].vat_category === 1) {
								debit_amount = amount - ((amount / 1.12) * (this.rate/100)) ;
							} else if(service[0].vat_category === 2) {
								debit_amount = (amount + (amount * (12 / 100))) - ((amount) * (this.rate/100)) ;
							} else { debit_amount = amount - (amount * (this.rate/100)); }
							
							total_amount = total_amount + debit_amount;
						} else if (ent.account_flag === 4){
							remarks = 'Withholding Tax';
							if(service[0].vat_category === 1) {
								debit_amount = (amount / 1.12) * (this.rate/100);
							} else if(service[0].vat_category === 2) {
								debit_amount = amount * (this.rate/100);
							} else { debit_amount = amount * (this.rate/100); }
							
							total_amount = total_amount + debit_amount;
						}
						details.push(
							{
								'billing_request_no': this.billingRequestNumber,
								'payee_id': this.payee_id,
								'department_id': this.currUser.dept_id,
								'branch_id': this.currUser.branch_id,
								'section_id': 0,
								'account_code': ent.account_code,
								'billing_service_id': ent.billing_service_id,
								'debit_amount': parseFloat(debit_amount.toFixed(2)),
								'credit_amount': parseFloat(credit_amount.toFixed(2)),
								'edit_by': this.currUser.id,
								'status': 4,
								'remarks': remarks
							}
						);
					});
				}
			});
			
			let header = {
				'billing_request_no': this.billingRequestNumber,
				'request_date': this.request_date,
				'payee_id': this.payee_id,
				'amount': numeral(total_amount).format('00.[00]'),
				'request_status': type === 'draft' ?  1 : 4,
				'department_id': 1,
				'branch_id': 1,
				'section_id': 1,
				'dept_approver1': this.dept_approver1,
				'dept_approver2': this.dept_approver2,
				'remarks': this.remarks,
				'edit_by': this.currUser.id
			};

			headers.push(header);
			let data = {};
			data.headers = headers;
			data.details = details;
			data.image_title = [];
			data.upload_file = [];

			for( var i = 0; i < this.upload_files.length; i++ ){
				let file = this.upload_files[i];
				data.image_title.push(file.name);
				data.upload_file.push(file);
			}

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(data, options);
			if(this.isvalid){
				this.$store.dispatch('trxbilling/saveBilling', convertedFormData);
			} else {
				this.createDialog = false;
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		},
		async setDetails(){
			// this.payee_id
			// let data = this.payees.filter(det => det.id === this.payee_id);
			
			await this.$store.dispatch('payefile/getPayeeDet', this.payee_id).then(response => {
				let data = response.data.data;
				this.address = data[0].house_unit + ' ' + data[0].street + ' ' + data[0].barangay + ' ' + data[0].municipality + ' ' + data[0].city; 
				this.business_style = data[0].business_style;
				this.TIN = data[0].tin;
				this.atc_code = data[0].atc_code;
				this.rate = data[0].tax_percent;
			});
			this.service_data = this.services.filter(det => det.payee_id === this.payee_id);
			
		},
		getApprover(data){
			let officers = [];
			if( this.billingapprovingofficers.data.length !== 0){
				officers = this.billingapprovingofficers.data.filter(det => det.level === data);
			}
			return officers;
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		addService(){
			let det = {service_id: '', amount: ''};
			this.details.push(det);
		},
		deleteService(key, detail) {
			console.log(key);
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});

			if (detail.id) {
				this.deleted.push({ id: detail.id });
			}
		},
	}
};
</script>
<style scoped>
  .remove-underline.v-text-field .v-input__control .v-input__slot:before {
    border-style: none;
  }
  .remove-underline.v-text-field .v-input__control .v-input__slot:after {
    border-style: none;
  }

  /*Remove spinners*/
  .input_number input[type='number'] {
    -moz-appearance: textfield;
    text-align: right;
  }

  .input_number input::-webkit-outer-spin-button,
  .input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.document-card-container.v-card--outlined,
	.document-form-container.v-card--outlined {
		-webkit-box-shadow: none !important;
    box-shadow: none !important;
	}

	.document-card-title {
		padding: 0;
	}

	.document-form-container.v-card--outlined {
		border: 0;
	}
</style>
